import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, Link } from 'gatsby'

import '../assets/scss/main.scss'

import iconlogo from '../images/logo_stamp_semi.png'

const Layout = ({ children, location, bgClass }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div>
      <div id="wrapper" className="page">
        <div id="logo"><Link to="/"><img src={iconlogo} alt="Nakatomi Inc!"/></Link></div>
        <div id="headernav">
          <ul>
            <li><Link to="/services">Products & Services</Link></li>
            <li><Link to="/clients">Clients</Link></li>
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
        <div className="page-container">
          {children}
        </div>
        <nav id="footernav">
          <div id="footerCopyright">&copy; Nakatomi Print Labs, All Rights Reserved</div>
          <ul className="icons">
            <li><a href="http://www.facebook.com/nakatomiinc" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="http://www.instagram.com/nakatomi_inc" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
          </ul>
        </nav>
      </div>
      <div id="bg" className={bgClass ? bgClass : 'default'} />
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
